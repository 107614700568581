import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

import '../scss/blog.scss'

const BlogPostTemplate = ({ data: { previous, next, post, allFile } }) => {

  const featuredImage = {
	data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
	alt: post.featuredImage?.node?.alt || ``,
  }

  const headerImage = {
	  data: allFile?.files[0]?.childImageSharp?.gatsbyImageData,
	  alt: 'Mission 22 Blog Header'
  }

  return (
	<Layout>
		<Seo title={post.title} description={post.excerpt} />
		<section className="hero" style={{minHeight: '450px'}}>
            <div className="background-image">
				{headerImage.data && (
					<GatsbyImage image={headerImage.data} transformOptions={{ fit: 'cover' }} alt={headerImage.alt} />
				)}
            </div>
            <div className="overlay">
				<h1>
					Mission 22 Blog
				</h1>
            </div>
        </section>
		<section className="post-wrapper">
			<div className="container">

				<aside>
					{post.author.node.name} / {post.date}
				</aside>

				<article className="blog-post" itemScope itemType="http://schema.org/Article">

					<header>
						<h1 itemProp="headline">
							{parse(post.title)}
						</h1>
						<nav className="blog-post-nav">
							<ul>
								<li>
									{previous && (
										<Link to={previous.uri} rel="newer">
											<span>&laquo;</span> newer
										</Link>
									)}
								</li>

								<li>
									{next && (
										<Link to={next.uri} rel="older">
											older <span>&raquo;</span>
										</Link>
									)}
								</li>
							</ul>
						</nav>
					</header>
					{featuredImage?.data && (
						<GatsbyImage
							image={featuredImage.data}
							alt={featuredImage.alt}
							style={{ marginBottom: 50 }}
						/>
					)}
					{!!post.content && (
						<section itemProp="articleBody">{parse(post.content)}</section>
					)}
				</article>
			</div>
		</section>
	</Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
	$id: String!
	$previousPostId: String
	$nextPostId: String
  ) {
	post: wpPost(id: { eq: $id }) {
	  id
	  excerpt
	  content
	  title
	  date(formatString: "MMMM DD, YYYY")
	  author {
		node {
			name
		}
	  }
	  featuredImage {
		node {
		  altText
		  localFile {
			childImageSharp {
			  gatsbyImageData(
				quality: 100
				placeholder: BLURRED
				layout: FULL_WIDTH
			  )
			}
		  }
		}
	  }
	}
	previous: wpPost(id: { eq: $previousPostId }) {
	  uri
	  title
	}
	next: wpPost(id: { eq: $nextPostId }) {
	  uri
	  title
	}
	allFile(filter: {name: {eq: "blog-header"}}) {
		files: nodes {
			childImageSharp {
				gatsbyImageData(
					quality: 100
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}
		}
	}
  }
`
